.monthview-selected {
  font-weight: bold;
  background-color: #F1F1F1 !important;
  color: #333 !important;
}

td {
  border-radius: 100% !important;
  padding: 8px !important;
}

.monthview-primary-with-event, .calendar-event-inner {
  background-color: #1a92d0!important;
}

table, td, th {
  border: 0 !important;
}

.box {
  position: relative;
}

.box:before {
  content:"";
  padding-top: 100%;
  display: block;
}

thead {
  color: var(--ion-color-primary) !important;
  text-transform: capitalize;
}

.monthview-selected {
  background: rgba(255, 255, 255, 0.10) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  color: white !important;
  font-weight: 500;
}

.monthview-current {
  color: var(--ion-color-primary) !important;
  font-weight: 500;
  background-color: transparent !important;

  &.monthview-selected {
    background-color: rgba(255, 255, 255, 0.10) !important;
  }
}

.monthview-primary-with-event, .monthview-secondary-with-event {
  background-color: transparent !important;

  &.monthview-selected {
    background-color: rgba(255, 255, 255, 0.10) !important;
  }

  .circle {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid;
    }

    &.left-primary::before {
      border-color: var(--ion-color-primary);
    }
    &.left-red::before {
      border-color: #F64655;
    }
    &.left-gray::before {
      border-color: #ccc;
    }

    &.right-primary::after {
      border-color: var(--ion-color-primary);
    }
    &.right-red::after {
      border-color: #F64655;
    }
    &.right-gray::after {
      border-color: #ccc;
    }

    &::before {
      /* border-color: var(--ion-color-primary); */
      clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
    }

    &::after {
      /* border-color: #F64655; */
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
    }
  }
}

.event-detail-container {
  border-top: 0 !important;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid transparent;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
}
