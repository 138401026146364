/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/css';
@import '@ionic/angular/css/ionic-swiper';

/* Normalize Ionic */
@import "./normalize.scss";
@import "./calendar.scss";

/* flag-icons */
@import "../../../node_modules/flag-icons/sass/flag-icons.scss";

/** Ionic CSS Variables **/
:root {
  --primary-shade: #085d60;
  --ion-font-family: "Inter";
  --ion-background-color-rgb: 4, 52, 67;
  --ion-background-color: #043443;

  --ion-color-primary-rgb: 29, 255, 207;
  --ion-color-primary: #1dffcf;

  --ion-text-color: #fffff;

  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;

  .alert-wrapper {
    background: var(--ion-background-color);
  }

  /** Toolbar **/
  --ion-toolbar-background: var(--ion-background-color);
  --ion-toolbar-border-color: var(--ion-background-color);
  --ion-toolbar-border-style: none;
  --ion-toolbar-border-width: 0px;
  --ion-toolbar-color: var(--ion-text-color);

  --ion-toolbar-padding-top: 0px;
  --ion-toolbar-padding-bottom: 0px;
  --ion-toolbar-padding-start: 0px;
  --ion-toolbar-padding-end: 0px;

  /** Loading **/
  ion-loading {
    --background: var(--ion-background-color);
    --spinner-color: var(--ion-color-primary);

    color: var(--ion-text-color);
  }

  /* Cupertino Pane */
  --cupertino-pane-background: var(--ion-background-color);
  --cupertino-pane-destroy-button-background: var(--primary-shade);
  --cupertino-pane-icon-close-color: var(--ion-background-color);

  --ion-color-step-150: #085D60;
  --ion-color-step-300: var(--ion-color-primary);

  --ion-color-step-850: #fff;
}

.white {
  --cupertino-pane-background: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Black.ttf") format("truetype");

  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-ExtraBold.ttf") format("truetype");

  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Bold.ttf") format("truetype");

  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-SemiBold.ttf") format("truetype");

  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Medium.ttf") format("truetype");

  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Regular.ttf") format("truetype");

  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Light.ttf") format("truetype");

  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-ExtraLight.ttf") format("truetype");

  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/static/Inter-Thin.ttf") format("truetype");

  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/static/Roboto-Medium.ttf") format("truetype");

  font-weight: 500;
}

.font-inter {
  font-family: Inter, Nunito, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

div.map {
  width: 100% !important;
  height: 100% !important;
  display: block;
  position: fixed !important;
}

ion-radio::part(container) {
  border-radius: 6px;
  border: 1px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--ion-color-primary);
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: var(--ion-background-color);

  transform: rotate(45deg);
}

.searchbar-input-container.sc-ion-searchbar-ios,
.searchbar-input.sc-ion-searchbar-ios {
  height: 100%;
  border-radius: 8px;
}

.list-md {
  padding: 0;
}

.cupertino-pane-wrapper .move {
  margin: 0 auto;
  height: 5px;
  background: #d9d9d980;
  width: 48px;
  border-radius: 100px;
}

.pac-item::before {
  content: '';
  mask-size: cover;
  background-color: #1dffcf;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 20px;
}

.pac-item {
  &.predicted::before {
    mask: url("../icons/place.svg") no-repeat 50% 50%;
    opacity: 0.5;
  }
  &.located::before {
    mask: url("../icons/geolocated.svg") no-repeat 50% 50%;
  }
}

*:focus {
  outline: none;
}

ion-toast {
  --background: #fff;
  --border-style: solid;
  --border-width: 2px;
  --box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.75);
  &.error {
    --color: theme(colors.red.500);
    --border-color: theme(colors.red.400);
  }
  &.info {
    --color: var(--ion-background-color);
    --border-color: var(--ion-background-color);
  }
  --button-color: var(--ion-background-color);
}

ion-modal {
  --border-radius: 28px;
  &.android {
    --border-radius: 28px 28px 0 0;
  }
  &#modalComparador {
    --background: #fff;
    pointer-events: none !important;
    --height: 475px;
    bottom: 96px;

    &::part(backdrop) {
      pointer-events: none !important;
    }

    &::part(handle) {
      background-color: black;
      width: 70px;
      height: 5px;
      border-radius: 100px;
      margin-top: 4px;
    }
  }
  &#departureTimeModal {
    --background: #fff;

    --ion-background-color-rgb: 255, 255, 255;
    --ion-background-color: #fff;

    --ion-text-color: #07205d;
    color: #07205d;
    --ion-color-step-150: #07205d4d;

    --ion-color-primary-rgb: 7, 32, 93;
    --ion-color-primary: #07205d;

    &::part(content) {
      margin-bottom: 16px;
    }
  }
  &#modalResumenCarpooling {
    --background: #fff;
    pointer-events: none !important;

    &::part(backdrop) {
      pointer-events: none !important;
    }
  }
  &#myWaiisModal {
    pointer-events: none !important;
    --height: 100%;
    --max-height: 100%;
    --top: 0;
    --margin-top: 0;

    &::part(backdrop) {
      pointer-events: none !important;
    }
  }
  &#locationPermissionsModal2, &#locationPermissionsModal {
    --width: 90%;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 24px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    bottom: 0;
    --backdrop-opacity: 0.8;
  }
  &#modalInform {
    --width: 90%;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 24px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    bottom: 0;
    --backdrop-opacity: 0.8;
  }
  &#dayEditModal {
    --height: "100%";
    --border-radius: 40px 40px 0px 0px;
    --backdrop-opacity: 0;
    --box-shadow: 0px -2px 22.5px 0px rgba(0, 0, 0, 0.25);
  }
  &#dayEditModal::part(content) {
    height: "100%";
    bottom: 0;
    position: absolute;
  }
}

ion-title {
  padding: 0 30px;
}

ion-content#paymentMethodsPage {
  --padding-start: 8px;
  --padding-end: 8px;
}

ion-datetime {
  --background: var(--ion-background-color);
}

ion-datetime::part(native) {
  background-color: #ff0000;
}

.ion-datetime-button-overlay ion-datetime.datetime-grid {
  width: 100%;
}

ion-datetime-button::part(native) {
  background-color: var(--ion-background-color);
  border: 1px solid #1dffcf;
  width: 100%;
  height: 48px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.picker-highlight {
  background-color: red;
}

.native-textarea[disabled] {
  &.sc-ion-textarea-ios {
    opacity: 1;
  }
  &.sc-ion-textarea-md {
    opacity: 1;
    padding-left: 0;
    padding-bottom: 0;
  }
}


ion-action-sheet .action-sheet-group {
  --button-color-selected: var(--ion-color-primary);
  --button-color-activated: var(--ion-color-primary);
  --button-background: var(--ion-background-color);
  --button-background-selected: var(--primary-shade);
  --button-background-activated: var(--primary-shade);
  --button-color: white;
  --button-background-selected-opacity: 0.5;
  background: var(--ion-background-color);
}

ion-action-sheet .action-sheet-cancel::after {
  background: var(--ion-background-color);
}

ion-action-sheet ion-backdrop {
  opacity: 0.6;
}

ion-select::part(placeholder) {
  color: white;
  opacity: 0.6;
}

ion-select::part(icon) {
  color: var(--ion-color-primary);
  opacity: 1;
  margin-right: 6px;
}

.safe-top {
  padding-top: max(16px, env(safe-area-inset-top));
}

.safe-bottom {
  padding-bottom: max(16px, env(safe-area-inset-bottom));
}

#deleteRoutineModal {
  --height: "100%";
  --border-radius: 40px 40px 0px 0px;
  --backdrop-opacity: 0;
  --box-shadow: 0px -2px 22.5px 0px rgba(0, 0, 0, 0.25);
  margin-top: 200px;
}

#deleteRoutineModal::part(content) {
  height: "100%";
  bottom: 0;
  position: absolute;
  background-color: var(--primary-shade);
}

.journeyMarker {
  margin-bottom: 48px
}
